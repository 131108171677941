// Load choices for select element improvement
import Choices from "choices.js";

/**
 * Ignore characters with diacritics (like ä, á etc.)
 */
function removeAccents(obj) {
    if (typeof obj === 'string' || obj instanceof String) {
        return obj.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    return obj
}

document.addEventListener("DOMContentLoaded", () => {
    const elements = Array.from(document.querySelectorAll(".js-choice"));
    elements.map((element) => {
        return new Choices(element, {
            removeItemButton: true,
            placeholder: true,
            placeholderValue: null,
            itemSelectText: "",
            noResultsText: window.uiLanguage.no_results,
            allowHTML: true,
            shouldSort: false,
            fuseOptions: {
                getFn: (obj, path) => {
                    const value = obj[path];
                    return removeAccents(value);
                }
            }
        });
    });
});
